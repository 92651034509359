/* eslint-disable max-lines */

import { UserReadArticlesState } from "../_components/reducers/userReadArticlesSlice";
import { Article } from "../_types";

export const determineChapterDepth = (
  readingListData: any,
  articleId: string,
): string | undefined => {
  const result = postOrderArticleTraversalSearch(readingListData, articleId);
  if (result && result.length > 1) {
    return result.slice(0, -1).join("_");
  }
  return undefined;
};

export const postOrderArticleTraversalSearch = (
  node: any,
  findId: string,
): any => {
  if (node?.type === "Article") {
    if (node.id === findId) {
      return [];
    } else {
      return null;
    }
  }

  if (!node?.list) return null;

  for (let i = 0; i < node.list.length; i++) {
    const result = postOrderArticleTraversalSearch(node.list[i], findId);
    if (result) {
      result.unshift(i);
      return result;
    }
  }
  return null;
};

export const postOrderArticleTraversal = (node: any, operation: any) => {
  if (node?.type === "Article") {
    operation(node);
    return;
  }

  if (!node?.list) return;

  for (let i = 0; i < node.list.length; i++) {
    postOrderArticleTraversal(node.list[i], operation);
  }
};

export const readingListCompletionDeep = (
  readingList: any,
  userReadArticles: any,
) => {
  const allArticles: { [key: string]: number } = {};
  const userArticles: { [key: string]: number } = {};
  postOrderArticleTraversal(readingList, (article: Article) => {
    const articleId = article.id;
    allArticles[articleId] = 1;
    if (
      userReadArticles &&
      Object.prototype.hasOwnProperty.call(userReadArticles, article.id)
    ) {
      userArticles[articleId] = 1;
    }
  });
  return {
    userReadCount: Object.keys(userArticles).length,
    allArticlesCount: Object.keys(allArticles).length,
  };
};

export const determinePrevAndNext = (
  readingListData: any,
  articleId: string,
) => {
  const orderedIds: string[] = [];
  postOrderArticleTraversal(readingListData, (node: any) =>
    orderedIds.push(node.id),
  );

  const articleIndex = orderedIds.findIndex((id) => articleId === id);
  const readingListId = readingListData.id;
  let prevNavigation = `/reading-list/${readingListId}/`;
  if (articleIndex > 0 && orderedIds.length > 0) {
    const prevArticleId = orderedIds[articleIndex - 1];
    const chapterDepth = determineChapterDepth(readingListData, prevArticleId);
    prevNavigation += `article/${prevArticleId}`;
    prevNavigation += chapterDepth ? `?chapterDepth=${chapterDepth}` : "";
  }

  let nextNavigation = `/reading-list/${readingListId}/`;
  if (articleIndex < orderedIds.length - 1 && orderedIds.length > 0) {
    const nextArticleId = orderedIds[articleIndex + 1];
    const chapterDepth = determineChapterDepth(readingListData, nextArticleId);
    nextNavigation += `article/${nextArticleId}`;
    nextNavigation += chapterDepth ? `?chapterDepth=${chapterDepth}` : "";
  }

  return {
    prevspan: "Previous",
    prevNavigation,
    nextspan: "Next",
    nextNavigation,
    isFirst: articleIndex === 0,
    isLast: articleIndex === orderedIds.length - 1,
    prevArticleId: orderedIds[articleIndex - 1],
    nextArticleId: orderedIds[articleIndex + 1],
  };
};

export function getArticleLink(readingListData: any, articleId: string) {
  const chapterDepth = determineChapterDepth(readingListData, articleId);
  return `/reading-list/${readingListData.id}/article/${articleId}${
    chapterDepth ? `?chapterDepth=${chapterDepth}` : ""
  }`;
}

export const displayedList = (readingList: any, chapterDepth?: string) => {
  if (!chapterDepth) {
    return readingList;
  }

  let list = readingList;
  const spl = chapterDepth.split("_");
  for (let i = 0; i < spl.length; i++) {
    const index = spl[i];
    const candidate = list?.list[index];
    if (candidate) {
      list = candidate;
    } else {
      break;
    }
  }

  return list;
};

export const formatMarkup = (
  text: string,
  navigation: any,
  readingListData: any,
): JSX.Element => {
  if (text === "") return <span></span>;

  const startIdx = nextMarkup(text);
  if (startIdx < 0) {
    return <span>{text}</span>; // no markup
  }

  if (startIdx > 0) {
    // the markup starts with plain text
    return (
      <>
        <span>{text.slice(0, startIdx)}</span>
        {formatMarkup(text.slice(startIdx), navigation, readingListData)}
      </>
    );
  }

  if (text.indexOf("<<") === 0) {
    // it's a link
    const endIdx = text.indexOf(">>");
    const splitIdx = text.indexOf(":");

    const linkspan = text.slice(startIdx + 2, splitIdx);
    const linkURL = text.slice(splitIdx + 1, endIdx);

    return (
      <>
        <button
          className="text-blue-600"
          onClick={() => {
            if (linkURL.indexOf("ARTICLE#") === 0) {
              const spl = linkURL.split("#");
              if (spl.length > 1) {
                const articleId = spl[1];
                navigation.push("Article", {
                  readingListId: readingListData.id,
                  chapterDepth: determineChapterDepth(
                    readingListData,
                    articleId,
                  ),
                  articleId,
                });
                return;
              }
            }
            window.location.href = linkURL;
          }}
        >
          {linkspan}
        </button>
        {formatMarkup(text.slice(endIdx + 2), navigation, readingListData)}
      </>
    );
  } else {
    // it's bold, italic, or underlined
    const command = text.slice(0, 2);
    const endIdx = text.indexOf(command, 2);
    return (
      <>
        <span
          style={
            command === "**" // bold
              ? { fontWeight: "bold" }
              : command === "__" // underline
                ? { textDecorationLine: "underline" }
                : { fontStyle: "italic" }
          }
        >
          {formatMarkup(text.slice(2, endIdx), navigation, readingListData)}
        </span>
        {formatMarkup(text.slice(endIdx + 2), navigation, readingListData)}
      </>
    );
  }
};

const nextMarkup = (text: string): number => {
  const link = text.indexOf("<<");
  const bold = text.indexOf("**");
  const underline = text.indexOf("__");
  const italic = text.indexOf("||");

  const first = Math.min.apply(
    null,
    [link, bold, underline, italic].filter((val) => val >= 0),
  );

  // don't call it an opening markup if none exist or if the text ends with that markup
  if (first < 0 || first === text.length - 2) return -1;

  return first;
};

export function getLastReadDateObj(userCompletes: string[] | null) {
  const zoneAwareString = userCompletes
    ? userCompletes[userCompletes.length - 1].charAt(
        userCompletes[userCompletes.length - 1].length - 1,
      ) !== "Z"
      ? userCompletes[userCompletes.length - 1] + "Z"
      : userCompletes[userCompletes.length - 1]
    : null;
  return zoneAwareString ? new Date(zoneAwareString) : null;
}

export const isArticleReadThisMonth = (
  videoId: string | undefined,
  userReadArticles: UserReadArticlesState,
  month?: string, // Optional month parameter
  year?: string, // Optional year parameter
) => {
  if (!videoId) return true; // If videoId is undefined, consider it watched (optional video)

  const videoCompletes = userReadArticles.articles[videoId];

  if (videoCompletes && videoCompletes.length > 0) {
    const lastWatchedDateObj = getLastReadDateObj(videoCompletes);

    if (month && year) {
      // Use the provided month and year
      const inputDate = new Date(`${month} 1, ${year}`);
      const validDate =
        inputDate > new Date() ? new Date() : new Date(`${month} 1, ${year}`);

      return (
        lastWatchedDateObj !== null &&
        lastWatchedDateObj >=
          new Date(validDate.getFullYear(), validDate.getMonth(), 1)
      );
    } else {
      // Use the current month and year
      const nowDateObj = new Date();
      return (
        lastWatchedDateObj !== null &&
        nowDateObj.getFullYear() === lastWatchedDateObj.getFullYear() &&
        nowDateObj.getMonth() === lastWatchedDateObj.getMonth()
      );
    }
  }

  return false;
};

// Helper function to get the previous month and year
export const getPreviousMonthYear = (month: string, yearStr?: string) => {
  const year = yearStr ? parseInt(yearStr, 10) : new Date().getFullYear();
  const monthIndex = new Date(`${month} 1, ${year}`).getMonth(); // 0-indexed

  let previousMonthIndex = monthIndex - 1;
  let previousYear = year;

  if (previousMonthIndex < 0) {
    previousMonthIndex = 11; // December
    previousYear--;
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const previousMonth = monthNames[previousMonthIndex];

  return { month: previousMonth, year: previousYear.toString() };
};

export function filterArticleReadsByMonthAndYear(
  articles: Record<string, string[]>, // Articles object
  month: string,
  year: number,
): Record<string, string[]> {
  // Get the current date
  const currentDate = new Date();
  const inputDate = new Date(`${month} 1, ${year}`);

  // If the input date is in the future, use the current month and year
  const validDate = inputDate > currentDate ? currentDate : inputDate;

  const monthIndex = validDate.getMonth(); // Numeric index of the valid month (0-based)
  const validYear = validDate.getFullYear(); // Valid year

  // Calculate the range of dates to include
  const startOfMonth = new Date(validYear, monthIndex, 1); // Start of the month
  const endOfMonth = new Date(validYear, monthIndex + 1, 0, 23, 59, 59, 999); // End of the month
  const endOfPreviousMonth = new Date(
    validYear,
    monthIndex,
    0,
    23,
    59,
    59,
    999,
  ); // End of the previous month
  const startOfWeekBeforeEndOfPreviousMonth = new Date(endOfPreviousMonth);
  startOfWeekBeforeEndOfPreviousMonth.setDate(
    startOfWeekBeforeEndOfPreviousMonth.getDate() - 6,
  ); // Subtract 6 days for a full week
  const startOfNextMonth = new Date(validYear, monthIndex + 1, 1); // Start of the next month

  const filteredArticles: Record<string, string[]> = {};

  for (const articleId in articles) {
    const dates = articles[articleId].filter((dateString) => {
      const date = new Date(dateString);

      // Check if the date falls in the range of the previous week, current month, or first day of next month
      return (
        (date >= startOfWeekBeforeEndOfPreviousMonth &&
          date <= endOfPreviousMonth) || // Last week of the previous month
        (date >= startOfMonth && date <= endOfMonth) || // Current month
        (date >= startOfNextMonth &&
          date < new Date(startOfNextMonth.getTime() + 24 * 60 * 60 * 1000)) // First day of next month
      );
    });

    if (dates.length > 0) {
      filteredArticles[articleId] = dates;
    }
  }

  return filteredArticles;
}
